<script>
export default {
	name: 'AppHeaderSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import OverlayDrawer from '~/components/OverlayDrawer.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import AppSpinner from '~/components/AppSpinner.vue';
import MobileDrawerHeader from '~/components/AppHeader/MobileDrawerHeader.vue';

import useDisableScroll from '~composables/useDisableScroll.js';
import { onClickOutside } from '@vueuse/core';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { ensureHtmlExtension, wrapQueryWithHTML, addRetainingQueryToUrl } from '~/logic/helpers/string.js';
import { debounce as _debounce } from 'lodash';
import { Url as ReURL, RawUrl as ReRawURL } from 'reurl';
import { stringifyQuery, parseQuery } from '~/logic/helpers/url-query.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	searchPageUrl: { type: String, default: '' },
	searchSuggestionUrl: { type: String, default: '' }, // used for API call,
	searchInputPlaceholderText: { type: String, default: '' },
	searchButtonLabelText: { type: String, default: '' },
	quickLinks: { type: Array, default: () => ([]) },
	quickLinksLabelText: { type: String, default: '' },
	suggestedSearchesLabelText: { type: String, default: '' },
	isHideAccountIcon: { type: Boolean, default: false },
	
	mobileLogoImgSrc: { type: String, default: null },
	mobileLogoImgAlt: { type: String, default: null },
	mobileLogoOpenInNewTab: { type: Boolean, default: false },
	closeMenuCTALabel: { type: String, default: '' },
});
const emit = defineEmits([
	'update:isVisible',
]);

const internalVisible = ref(props.isVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});
watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

useDisableScroll(internalVisible);

const hideOverlay = () => {
	internalVisible.value = false;
};

const QUERY_URL_KEY = 'q';
const rootEl = ref(null);
const searchInputEl = ref(null);

const focusSearchInput = () => {
	searchInputEl.value?.focus();
};
const selectAllTextSearchInput = () => {
	searchInputEl.value?.select();
};


const handleRootBlurredWithin = (event) => {
	internalVisible.value = false;
};



onClickOutside(rootEl, (event) => {
	internalVisible.value = false;
});

const keywordUrlQuery = useRouteQuery(QUERY_URL_KEY);
const searchInputVal = ref('');
const router = useRouter();

const searchPageUrlWithExtension = computed(() => {
	return ensureHtmlExtension(props.searchPageUrl ?? '');
});

const isInSearchPage = computed(() => {
	return router.currentRoute.value.path === searchPageUrlWithExtension.value;
});

const handleSearchSubmit = () => {
	if (searchInputVal.value.length < minSearchTextLength.value) return;
	internalVisible.value = false;

	if (!isInSearchPage.value && props.searchPageUrl) {
		// not in search page, perform redirect
		// router.push({ path: searchPageUrlWithExtension.value, query: { [QUERY_URL_KEY]: searchInputVal.value } });
		
		const processedHref = ensureHtmlExtension(addRetainingQueryToUrl(searchPageUrlWithExtension.value));
		const encodedSearchValue = encodeURIComponent(searchInputVal.value);
		const _reUrl = new ReRawURL(processedHref);
		const stringifiedQuery = stringifyQuery({
			...parseQuery(_reUrl.query || ''),
			[QUERY_URL_KEY]: encodedSearchValue,
		});
		const resultReUrl = _reUrl.set({ query: stringifiedQuery });
		const finalLocation = resultReUrl.toString().replaceAll('%20', '+');
		window.location = finalLocation;
	} else {
		// just update the URL will do
		router.replace({ query: { [QUERY_URL_KEY]: searchInputVal.value } });
	}
};


const isLoading = ref(false);
const apiErrorMsg = ref('');
const apiResponse = ref(null);

const apiPath = computed(() => {
	return `${props.searchSuggestionUrl}?keyword=${searchInputVal.value}`;
});

const fetchSearchSuggestion = async () => {
	let _apiResponse = null;

	isLoading.value = true;
	apiErrorMsg.value = '';

	try {
		_apiResponse = await axios.get(apiPath.value);
		apiResponse.value = _apiResponse.data;
	} catch {
		apiErrorMsg.value = 'Unable to fetch search suggestions';
	}

	isLoading.value = false;
};

const searchInputHasCnJnKrChar = computed(() => {
	let regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
	return searchInputVal.value.length && regex.test(searchInputVal.value);
});

const minSearchTextLength = computed(() => {
	return searchInputVal.value.length && searchInputHasCnJnKrChar.value ? 2 : 3;
});

const handleOnInput = _debounce(() => {
	if (searchInputVal.value.length < minSearchTextLength.value) {
		apiResponse.value = null;
		return;
	}
	fetchSearchSuggestion();
}, 1000);

const suggestions = computed(() => {
	return apiResponse.value?.pages ?? [];
});



watch(keywordUrlQuery, (newValue) => {
	searchInputVal.value = newValue ? decodeURIComponent(newValue) : '';
	handleOnInput();
}, { immediate: true });


const siteName = window.siteName;


</script>

<template>
<template v-if="isMobileViewport">
	<!-- Mobile -->
	<OverlayDrawer
		:isVisible="props.isVisible"
		:style="{ '--overlay-container-bg-color':
			siteName === 'firefly' ? 'var(--neutral-firefly-white-base)' : 'var(--secondary-blue-extradark)' }"
		@update:is-visible="$emit('update:isVisible', $event)"
		@vue:mounted="focusSearchInput(); selectAllTextSearchInput();"
	>
		<div class="Search-mobile text-white overflow-y-auto max-h-screen" :data-use-theme="siteName">
			<MobileDrawerHeader
				class="sticky top-0 bg-secondary-blue-extradark"
				:logoImgSrc="props.mobileLogoImgSrc"
				:logoImgAlt="props.mobileLogoImgAlt"
				:logoOpenInNewTab="props.mobileLogoOpenInNewTab"
				:drawerIsHideAccountIcon="props.isHideAccountIcon"
				@click:header-x="internalVisible = false"
			/>

			<div class="inner-wrapper px-6 pt-4 pb-6">
				<form class="flex-grow relative" @submit.prevent="handleSearchSubmit">
					<input
						ref="searchInputEl"
						v-model="searchInputVal"
						class="input-search w-full h-full pl-12 rtl:pl-3 rtl:pr-12 py-4 bg-transparent border-neutral-grey-extradark"
						type="search"
						:placeholder="props.searchInputPlaceholderText"
						@input="handleOnInput"
					/>
					<icon-far-magnifying-glass class="fill-neutral-grey-extradark absolute my-auto inset-y-0 left-4 rtl:right-4" />
				</form>

				<div v-if="isLoading" class="flex items-center justify-center h-[200px]">
					<AppSpinner class="!text-white" />
				</div>

				<div v-else class="pt-8">
					<div v-if="apiErrorMsg" class="mb-8">
						<p class="text-red-400">{{ apiErrorMsg }}</p>
					</div>

					<template v-if="apiResponse !== null && !apiErrorMsg">
						<template v-if="suggestions.length > 0">
							<div class="typo-category-title mt-8">{{ props.suggestedSearchesLabelText }}</div>
							<ul class="suggestion-list mt-4 flex flex-col text-neutral-grey-dark">
								<li
									v-for="item in suggestions.slice(0, 5)"
									:key="item.path"
									class="suggestion-list-item"
								>
									<AppHyperlink
										class="search-link"
										:href="`${searchPageUrlWithExtension}?q=${item.title}`"
										@click="internalVisible = false"
									>
										<span v-html-sanitize="wrapQueryWithHTML(item.title, { query: searchInputVal, className: 'mark' })"></span>
									</AppHyperlink>
								</li>
							</ul>
						</template>
					</template>

					<template v-else>
						<div class="typo-category-title mt-8">{{ props.quickLinksLabelText }}</div>
						<ul class="mt-4 flex flex-col">
							<li
								v-for="quickLink in props.quickLinks"
								:key="quickLink.path"
							>
								<AppHyperlink
									v-if="siteName !== 'enrich-portal'"
									:href="quickLink.path"
									class="search-link"
								>{{ quickLink.title }}</AppHyperlink>

								<AppHyperlink
									v-else
									:href="quickLink.resolvedPath"
									class="search-link"
								>{{ quickLink.title }}</AppHyperlink>
							</li>
						</ul>
					</template>
				</div>
			</div>
		</div>
	</OverlayDrawer>
</template>

<template v-else>
	<!-- Desktop -->
	<Teleport to="body">
		<div class="generic-backdrop"></div>
	</Teleport>
	<!-- 'transform' class is for Safari fix -->
	<div
		v-if="isVisible"
		ref="rootEl"
		v-focus-within
		class="Search-desktop bg-white overflow-hidden transform"
		v-bind="$attrs"
		tabindex="-1"
		:data-use-theme="siteName"
		@keydown.esc.capture="hideOverlay"
		@blur-within="handleRootBlurredWithin"
		@vue:mounted="focusSearchInput(); selectAllTextSearchInput();"
	>
		<div class="generic-container pt-8 relative">
			<AppHyperlink
				v-if="props.closeMenuCTALabel"
				href="#"
				class="absolute right-4"
				@click="hideOverlay"
			>
				<span class="font-semibold pr-2">{{ props.closeMenuCTALabel }}</span>
				<icon-far-xmark class="w-2.5 inline-block fill-current mt-[3px]" />
			</AppHyperlink>
		</div>
		<div class="w-full max-w-8/12 mx-auto my-22">
			<form class="flex gap-x-6" @submit.prevent="handleSearchSubmit">
				<div class="flex-grow relative">
					<input
						ref="searchInputEl"
						v-model="searchInputVal"
						class="input-search w-full h-full pl-12 rtl:pl-3 rtl:pr-12"
						type="search"
						:placeholder="props.searchInputPlaceholderText"
						@input="handleOnInput"
					/>
					<icon-far-magnifying-glass class="fill-primary-blue-base absolute my-auto inset-y-0 left-4 rtl:right-4" />
				</div>
				<div class="flex-0 max-w-[134px]">
					<CTAButton
						:ctaText="props.searchButtonLabelText"
						sizeType="medium"
						:isSubmit="true"
						:disabled="searchInputVal.length < minSearchTextLength"
					/>
				</div>
			</form>

			<div v-if="isLoading" class="flex items-center justify-center h-[200px]">
				<AppSpinner />
			</div>

			<div v-else class="pt-8">
				<div v-if="apiErrorMsg" class="mb-8">
					<p class="text-red-400">{{ apiErrorMsg }}</p>
				</div>

				<template v-if="apiResponse !== null && !apiErrorMsg">
					<template v-if="suggestions.length > 0">
						<div class="typo-category-title mt-8">Suggested Searches</div>
						<ul class="suggestion-list mt-4 flex flex-col text-neutral-grey-extradark">
							<li
								v-for="item in suggestions.slice(0, 5)"
								:key="item.path"
								class="suggestion-list-item"
							>
								<AppHyperlink
									class="search-link"
									:href="`${searchPageUrlWithExtension}?q=${item.title}`"
								>
									<span v-html-sanitize="wrapQueryWithHTML(item.title, { query: searchInputVal, className: 'mark' })"></span>
								</AppHyperlink>
							</li>
						</ul>
					</template>
				</template>

				<template v-else>
					<div class="typo-category-title mt-8">{{ props.quickLinksLabelText }}</div>
					<ul class="mt-4 flex flex-col">
						<li
							v-for="quickLink in props.quickLinks"
							:key="quickLink.path"
						>
							<AppHyperlink
								v-if="siteName !== 'enrich-portal'"
								:href="quickLink.path"
								class="search-link"
								@click="internalVisible = false"
							>{{ quickLink.title }}</AppHyperlink>
							<AppHyperlink
								v-else
								:href="quickLink.resolvedPath"
								class="search-link"
								@click="internalVisible = false"
							>{{ quickLink.title }}</AppHyperlink>
						</li>
					</ul>
				</template>

			</div>
		</div>
	</div>
</template>


</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';


.Search-desktop {
	position: fixed;
	z-index: 7000;
	left: 0;
	width: 100%;
	// after applying transform to header, this 'top' need to change
	/* top: calc( var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight) + var(--headerHeight) ); */
	top: var(--headerHeight);
	transition: opacity 0.3s ease;
	min-height: 500px;
	@apply shadow-type-a;

	.input-search::-webkit-search-decoration {
		display: none;
	}
}

.suggestion-list-item {
	--a-tag-color: inherit;
	
	:deep(.mark) {
		color: var(--text-color);
		background-color: transparent;
	}
}

.search-link {
	display: block;
	width: 100%;
	--a-tag-color: inherit;
	@apply px-4 py-2 rounded-lg;
	
	&:hover, &:focus-visible {
		text-decoration: none;
		--a-tag-color: var(--primary-blue-base);
		background-color: var(--neutral-grey-extralight);
		
		@media #{var.$query-max-md} {
			color: white;
			background-color: var(--primary-blue-dark);
		}
	}
}

.Search-mobile {
	:deep(.mark) {
		color: white;
	}
	
	.search-link {
		&:hover, &:focus-visible {
			color: var(--neutral-grey-dark);
			background-color: var(--primary-blue-dark);
		}
	}

	.input-search::-webkit-search-decoration {
		display: none;
	}
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
    height: 20px;
    width: 20px;
	background-color: var(--neutral-grey-dark);
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path></svg>');
    cursor: pointer;
}

.Search-desktop[data-use-theme="firefly"] {
	.input-search:focus-visible {
		outline-color: var(--primary-firefly-orange-base);
		outline-style: solid;
	}
	svg.fill-primary-blue-base {
		fill: var(--primary-firefly-orange-base);
	}
	
	.search-link {
		&:hover, &:focus-visible {
			color: var(--text-color);
			background-color: var(--secondary-firefly-orange-ultralight);
		}
	}
}

.Search-mobile[data-use-theme="firefly"] {

	@apply text-primary-firefly-black-base;

	.input-search {
		border-color: var(--neutral-firefly-grey-light);

		&:focus-visible {
			outline-color: var(--primary-firefly-orange-base);
			outline-style: solid;
		}
	}

	form svg {
		@apply fill-primary-firefly-orange-base;
	}

	:deep(.mark) {
		color: var(--primary-firefly-black-base);
	}

	.suggestion-list {
		@apply text-neutral-grey-dark;
	}

	.search-link {
		&:hover, &:focus-visible {
			color: var(--text-color);
			background-color: var(--secondary-firefly-orange-ultralight);
		}
	}
}

</style>
