<script>
export default {
	name: 'HeaderNotification',
	inheritAttrs: false,
};
</script>

<script setup>
import { getCookie, setCookie } from '~/logic/helpers/cookies.js';
import VueSwiper from '~/components/VueSwiper.vue';
import { useGlobalCookieSettings } from '~/logic/global-state/cookie-settings.js';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';


const props = defineProps({
	autoRotationTimer: { type: Number, default: 3 },
	children: { type: Array, default: () => ([]) },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	referencePath: { type: String, default: '' },
});
const { isEditorMode } = useGlobalAEMState();
const isVisible = ref(true);

const COOKIE_ID = 'HEADER_NOTIFICATION_VISIBLE';

const { isEnabledCookiePreferences, registerPreferenceCookie } = useGlobalCookieSettings();
registerPreferenceCookie(COOKIE_ID);

let isAutoplay = false; // intentionally non-reactive
let isLoop = false; // intentionally non-reactive

const dismissNotification = () => {
	isVisible.value = false;
	setCSSCustomPropertyToZero();
	
	const d = new Date();
	const remainingTime = (24 * 60 * 60 * 1000) - ((d.getHours() * 60 * 60 * 1000) + (d.getMinutes() * 60 * 1000) + (d.getSeconds() * 1000) + d.getMilliseconds());
	d.setTime(d.getTime() + remainingTime); // set expired time to midnight 12am
	let expires = d.toUTCString();
	if (isEnabledCookiePreferences.value) setCookie(COOKIE_ID, 'hidden', expires);
};


const swiperEl = ref(null);

// TODO: Focus within and blur within need to detect for keyboard interactions only
const handleRootFocusedWithin = () => {
	swiperEl.value?.swiperInstance?.autoplay.stop();
	swiperEl.value?.swiperInstance?.keyboard?.enable();
};
const handleRootBlurredWithin = () => {
	swiperEl.value?.swiperInstance?.autoplay.start();
	swiperEl.value?.swiperInstance?.keyboard?.disable();
};

const currentSlideLink = ref(props.children.at(0)?.pageUrl);

const handleSwiperIndexChange = (swiperInstance) => {
	currentSlideLink.value = props.children[swiperInstance.realIndex]?.pageUrl;
};

const setCSSCustomPropertyToZero = () => {
	document.documentElement.style.setProperty('--headerNotificationHeight', '0px');
};


if (props.children.length > 1) {
	isAutoplay = {
		delay: props.autoRotationTimer * 1000,
		disableOnInteraction: false,
	};
	isLoop = true;
} else if (props.children.length === 0) {
	isVisible.value = false;
	setCSSCustomPropertyToZero();
}

if (getCookie(COOKIE_ID)) {
	isVisible.value = false;
	setCSSCustomPropertyToZero();
}

if ((isMobileViewport.value && props.isHideInMobile) || (!isMobileViewport.value && props.isHideInDesktop)) {
	setCSSCustomPropertyToZero();
}

const siteName = window.siteName;
</script>


<template>
<template v-if="isEditorMode && props.isHideInDesktop">
	<div class="generic-container py-10 text-center">
		<p>Hidden in: <span v-if="props.isHideInDesktop">Desktop</span> <span v-if="props.isHideInMobile">, Mobile</span></p>
	</div>
</template>
<template v-else>
	<div
		v-if="((isMobileViewport && !props.isHideInMobile)
			|| (!isMobileViewport && !props.isHideInDesktop))
			&& isVisible"
		v-focus-within
		class="HeaderNotification bg-semantic-orange-extralight border-t-4 border-semantic-orange-base py-2"
		data-is-sticky
		data-height-var="--headerNotificationHeight"
		:data-use-theme="siteName"
		@focus-within="handleRootFocusedWithin"
		@blur-within="handleRootBlurredWithin"
	>
		<div class="main-wrapper generic-container">
			<div class="flex flex-grow">
				<div class="mr-3 flex-shrink-0 rtl:mr-0 rtl:ml-3">
					<AppHyperlink
						:href="props.referencePath"
						class="relative h-8 w-8"
					>
						<icon-far-bell class="h-4.5 fill-primary-black-base absolute bottom-1 left-1 " />
						<span class="icon red-dote">{{ props.children.length }}</span>
					</AppHyperlink>
				</div>
				<AppHyperlink class="notification-link" :href="currentSlideLink">
					<VueSwiper
						ref="swiperEl"
						direction="vertical"
						:slidesPerView="1"
						:autoplay="isAutoplay"
						:loop="isLoop"
						:simulateTouch="false"
						@active-index-change="handleSwiperIndexChange"
					>
						<div
							v-for="(slide, index) in children"
							:key="index"
							class="notification-title  font-semibold text-xs flex items-center h-full"
						>
							<p class="line-clamp-1 md:line-clamp-2">
								<span class="md:hidden">Important News: </span>
								<span class="notification-text-content">{{ slide.pageTitle }}</span>
							</p>
						</div>
					</VueSwiper>
				</AppHyperlink>
			</div>
			<div class="flex flex-shrink-0">
				<AppHyperlink
					:href="props.referencePath"
					class="HeaderNotificationCtaButton"
				>
					<span
						class="text-center line-clamp-2 break-words"
					>
						View all</span>
				</AppHyperlink>
				<button
					class="p-0 ml-10 md:ml-5 rtl:ml-0 rtl:mr-10 rtl:md:mr-5"
					type="button"
					:aria-label="$t('Dismiss notification')"
					@click="dismissNotification"
				>
					<div class="w-6 h-6 flex items-center justify-center">
						<icon-fas-xmark class="w-2.5 fill-primary-black-base" />
					</div>
				</button>
			</div>
		</div>
	</div>
</template>
</template>



<style lang="scss">
html {
	--headerNotificationHeight: 52px;
	--headerNotificationTranslateY: 0%;
}
.global-header-notification {
	/* position: sticky; */
	position: fixed;
	top: var(--mobileDlAppNotificationHeight);
	z-index: 8020;
	height: var(--headerNotificationHeight);
	transform: translateY(var(--headerNotificationTranslateY));
	transition: transform var(--pinnedElementTransitionDuration);
	
	> .aem-GridColumn {
		float: none !important;
	}
}
.aem-AuthorLayer-Edit, .aem-AuthorLayer-structure {
	.global-header-notification {
		position: static;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HeaderNotification {
	height: var(--headerNotificationHeight);
	/* position: relative;
	z-index: 8000; */
	
	.swiper {
		@apply h-8;
	}

	.notification-title {
		@apply hover:text-primary-blue-light hover:underline;
	}
}

.HeaderNotificationCtaButton {
	display: flex;
	align-items: center;
	color: var(--primary-blue-base);

	@apply font-semibold text-xs max-w-20 ml-10 md:ml-5;

	&:hover {
		color: var(--primary-blue-light);
		text-decoration: underline;
	}

	[dir="rtl"] & {
		@apply ml-0 mr-10 md:mr-5;
	}
}

.main-wrapper {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
}

/* .cta-btn {
	color: var.$primary-blue-base;
	@apply font-semibold;
	transition: 0.25s ease-in-out;
	
	&:hover {
		color: var.$primary-blue-light;
		@apply underline;
		
		svg {
			fill: var.$primary-blue-light;
		}
	}
	
	svg {
		fill: var.$primary-blue-base;
	}
} */

.notification-link {
	--focus-visible-outline-offset: 0px;
}

.icon.red-dote {
	background: var(--semantic-red-base);
	padding: 1px 6px;
	font-size: 10px;
	border-radius: 9px;
	color: var(--neutral-white-base);
	position: absolute;
	right: 0;
	border: 1px solid var(--semantic-orange-extralight);

	@apply font-semibold;

	[dir="rtl"] & {
		right: auto;
		left: -2px;
	}
}

[data-use-theme="firefly"] {

	&.HeaderNotification {
		border-color: var(--secondary-firefly-orange-base);
	}
	
	.notification-title {
		color: var(--primary-black-base);

		&:hover {
			color: var(--secondary-firefly-orange-medium);
		}
	}

	.HeaderNotificationCtaButton {
		color: var(--primary-black-base);

		&:hover {
			color: var(--secondary-firefly-orange-medium);
		}
	}
}

</style>
