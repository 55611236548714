<script>
export default {
	name: 'FlightSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import OverlayBonusSideTrip from '~/components/BookingWidget/BonusSideTrip/OverlayBonusSideTrip.vue';
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import VueTabs from '~/components/VueTabs.vue';
import SubTabBookFlight from '~/components/BookingWidget/FlightSearch/SubTabBookFlight.vue';
import SubTabMultiCity from '~/components/BookingWidget/FlightSearch/SubTabMultiCity.vue';
import SubTabBonusSideTrip from '~/components/BookingWidget/FlightSearch/SubTabBonusSideTrip.vue';
import ContinueYourSearch from '~/components/BookingWidget/FlightSearch/ContinueYourSearch.vue';
import usePropPassThrough from '~/logic/composables/usePropPassThrough.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { onClickOutside, useEventBus } from '@vueuse/core';
import { gsap } from 'gsap';
import { getAncestorBySelector } from '~/logic/helpers/dom.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';
import { pushAA_flightSearchStart, pushAA_flightSearchEnd, pushAA_click } from '~/logic/adobe-analytic.js';
import { useHeaderScroll } from '~/logic/composables/useHeaderScroll.js';
import { sleep } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	bonusSideTrip: { type: Object, default: null },
	bookFlight: { type: Object, default: null },
	labels: { type: Object, default: null },
	mobileAddOns: { type: Object, default: null },
	multiCity: { type: Object, default: null },
	isInBookingWidget: { type: Boolean, default: false },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },
	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },
	countryVariants: { type: Array, default: () => [] },
	// Props to hide on other page than homepage.
	isHideContinueYourSearchSection: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

});

const emit = defineEmits([
	'update:active-sub-tab',
]);

const rootEl = ref(null);
const subTabBookFlightEl = ref(null);
const subTabBonusSideTripEl = ref(null);
const vueTabsComponent = ref(null);
const isEnrichPortal = (window.siteName === 'enrich-portal');

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['flight-search', 'flight-search-tab'],
	rootEl,
});

const { isEditorMode } = useGlobalAEMState();

const activeSubTab = ref('book-flight');
const activeSubTabPreStick = ref('book-flight');
const isSticked = ref(false);

onMounted(() => {
	const switchTabContent = getAncestorBySelector(rootEl.value, '.SwitchTabContent');
	onClickOutside(switchTabContent?.[0], (event) => {
		if (isSticked.value) stickyCollapse();
	});
});

const handleRootFocusWithin = () => {
	if (isSticked.value) stickyExpand();
};
const handleRootBlurWithin = () => {
	//
};

const bookingWidgetBus = useEventBus('booking-widget');

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			handleOnStick(payload);
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			handleOnUnstick(payload);
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleOnStick = async (payload) => {
	// console.log('handleOnStick');
	activeSubTabPreStick.value = activeSubTab.value;

	// const beforeStickHeight = payload.triggerEl.offsetHeight;
	payload.resizeObserver.unobserve(payload.triggerEl);
	// debugger;
	// payload.rootEl.value.style.setProperty('--pinSpacingHeight', `${beforeStickHeight}px`);

	stickyCollapse({ skipAnimation: true });
	/*
	const beforeStickHeight = payload.triggerEl.offsetHeight;

	stickyCollapse({ skipAnimation: false });

	const pinSpacerEl = payload.rootEl.value.querySelector('.pin-spacer');
	const afterStickHeight = 112;
	pinSpacerEl.__beforeStickHeight = beforeStickHeight;

	gsap.fromTo(pinSpacerEl, {
		height: beforeStickHeight,
	}, {
		height: afterStickHeight + 120,
		duration: 0.35,
		overwrite: true,
	}); */

	/* const pinSpacerEl = payload.rootEl.value.querySelector('.pin-spacer');

	gsap.to(pinSpacerEl, {
		height: 112 + 120,
		duration: 0,
		overwrite: true,
	}); */

	const { paused: headerScrollPaused } = useHeaderScroll();


	/* headerScrollPaused.value = true;
	const pinSpacerEl = payload.rootEl.value.querySelector('.pin-spacer');
	gsap.to(pinSpacerEl, {
		height: beforeStickHeight,
		// duration: 0.3,
		duration: 0,
		overwrite: true,
		onComplete: () => {
			headerScrollPaused.value = false;
		},
	}); */

	headerScrollPaused.value = true;

	// debugger;
	await sleep(200);
	payload.triggerEl.classList.remove('pre-stick');

	gsap.fromTo(payload.triggerEl, {
		y: '-100%',
	}, {
		y: 0,
		duration: 0.3,
		overwrite: true,
		onComplete: () => {
			headerScrollPaused.value = false;
		},
	});
};
const handleOnUnstick = async (payload) => {
	// console.log('handleOnUnstick');
	activeSubTab.value = activeSubTabPreStick.value;
	stickyExpand({ skipAnimation: true });

	/* stickyExpand({ skipAnimation: false });

	const pinSpacerEl = payload.rootEl.value.querySelector('.pin-spacer');

	gsap.to(pinSpacerEl, {
		height: pinSpacerEl.__beforeStickHeight,
		duration: 0.35,
		overwrite: true,
		onComplete: () => {
			payload.scrollTriggerInstance.refresh();
		},
	}); */
	// payload.scrollTriggerInstance.refresh();
	// await nextTick();
	// payload.scrollTriggerInstance.refresh();
	await nextTick();
	// payload.triggerEl.classList.remove('pre-stick');
	await sleep(200);

	payload.resizeObserver.observe(payload.triggerEl);
};
const stickyExpand = ({ skipAnimation = false } = {}) => {
	// console.log('stickyExpand');
	const tabRootEl = rootEl.value.querySelector('.VueTabs');
	const expandableWrapperEL = tabRootEl.querySelector('.SubTabBookFlight .TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');

	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 'auto',
		onComplete: () => {
			gsap.set(expandableWrapperEL, {
				overflow: 'visible',
			});
		},
		overwrite: true,
	});

	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 'auto',
			overwrite: true,
		});
	}
	gsap.to(tabRootEl, {
		duration: skipAnimation ? 0 : 0.2,
		marginTop: 0,
		overwrite: true,
	});
};
const stickyCollapse = async ({ skipAnimation = false } = {}) => {
	// console.log('stickyCollapse');
	const CONTAINER_PADDING_TOP = 32;
	const tabRootEl = rootEl.value.querySelector('.VueTabs');
	const tabSwiperEl = tabRootEl.querySelector('.VueTabs .VueSwiper');
	const TAB_HEIGHT = tabSwiperEl.offsetHeight;
	const OFFSET_TOP = CONTAINER_PADDING_TOP + TAB_HEIGHT;
	const expandableWrapperEL = tabRootEl.querySelector('.SubTabBookFlight .TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');

	activeSubTab.value = 'book-flight';

	gsap.set(expandableWrapperEL, {
		overflow: 'hidden',
	});
	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 0,
		overwrite: true,
	});
	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 0,
			overwrite: true,
		});
	}

	gsap.to(tabRootEl, {
		duration: skipAnimation ? 0 : 0.2,
		marginTop: `-${OFFSET_TOP}`,
		overwrite: true,
	});
};


const continueYourSearchComponent = ref(null);

const handleSaveSearchData = (data) => {
	continueYourSearchComponent.value.saveSearch(data);
};

const restorePastSearch = async (searchData) => {
	// mobile subpage is not required for enrich
	if (isMobileViewport.value && !isEnrichPortal) {
		// activate the tab...
		isMobileSubpageVisible.value = true;
		await nextTick();
	}

	if (searchData.isBSTTab) {
		activeSubTab.value = 'bonus-side-trip';
		await nextTick();
		vueTabsComponent.value.scrollToTab('bonus-side-trip');
		subTabBonusSideTripEl.value.restorePastSearch(searchData);
	} else {
		activeSubTab.value = 'book-flight';
		await nextTick();
		vueTabsComponent.value.scrollToTab('book-flight');
		subTabBookFlightEl.value.restorePastSearch(searchData);
		subTabBookFlightEl.value.isExpanded = true;
	}
};

const isBSTOverlayVisible = ref(false);

const { commitDataToBSTOverlay } = useBSTOverlayInterimData();

const handleShowBSTOverlay = () => {
	isBSTOverlayVisible.value = true;
};

const handleFillUpForm = async (payload) => {
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
		await nextTick();
	}

	activeSubTab.value = 'book-flight';
	activeSubTabPreStick.value = 'book-flight';
	await nextTick();
	vueTabsComponent.value.scrollToTab('book-flight');
	subTabBookFlightEl.value.updateLocationFromTo(payload);
};


const globalBookFlightBus = useEventBus('booking-widget:book-flight');

globalBookFlightBus.on(async (event, payload) => {
	switch (event) {
		case 'active-tab-and-fill-up-form':
			handleFillUpForm(payload);
			await nextTick();
			break;
		default:
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
	}
});

//  AA for sub tab click tracking
watch(activeSubTab, (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'FlightSearch',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
});

let AAHasSentStart = false;
let AAHasSentEnd = false;

onMounted(() => {
	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;

		AAHasSentStart = true;
		pushAA_flightSearchStart(activeSubTab.value);
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};
	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});

const sendFormCompleteAA = (subtabName) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;
	pushAA_flightSearchEnd(subtabName);
};

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}

	const newSubTab = query.subtab ?? '';
	if (newSubTab) {
		activeSubTab.value = newSubTab;
		emit('update:active-sub-tab', newSubTab);
	}
});

</script>



<template>
<div
	ref="rootEl"
	v-focus-within
	class="FlightSearch booking-widget-subcomponent"
	tabindex="-1"
	:class="{
		'isEditorMode': isEditorMode,
	}"
	@focus-within="handleRootFocusWithin"
	@blur-within="handleRootBlurWithin"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Flight Search</span>
		</h5>

		<VueTabs
			ref="vueTabsComponent"
			v-model="activeSubTab"
			:dormantMode="isEditorMode"
			renderPanelsWithVShow
			@update:modelValue="$emit('update:active-sub-tab', $event)"
		>
			<template v-if="!props.bookFlight?.general?.isHideInDesktop && props.labels?.flightType?.bookFlightLabel" #tab-book-flight>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.labels?.flightType?.bookFlightLabel }}</span>
				</div>
			</template>
			<template v-if="!props.multiCity?.general?.isHideInDesktop && props.labels?.flightType?.multiCityLabel" #tab-multi-city>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.labels?.flightType?.multiCityLabel }}</span>
				</div>
			</template>
			<template v-if="!props.labels?.flightType?.isHideInDesktop && props.labels?.flightType?.roundTheWorldLabel" #link-round-the-world>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.labels?.flightType?.roundTheWorldLabel,
							clickComponentType: 'URL',
							componentName: 'FlightSearch',
							componentID: props.componentIdAA,
						},
						{
							name: props.labels?.flightType?.roundTheWorldLabel,
							type: isUrlMatchCurrentHostname(props.url) ? 'other' : 'exit',
						},
					]"
					:href="props.labels?.flightType?.roundTheWorldURL"
					class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
					:target="props.labels?.flightType?.openInNewTab ? '_blank' : '_self'"
					:autoExternalLinkIcon="true"
				>{{ props.labels?.flightType?.roundTheWorldLabel }}</AppHyperlink>
			</template>
			<template v-if="!props.bonusSideTrip?.general?.isHideInDesktop && props.labels?.flightType?.bonusSideTripLabel" #tab-bonus-side-trip>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.labels?.flightType?.bonusSideTripLabel }}</span>
				</div>
			</template>

			<div
				v-if="!props.bookFlight?.general?.isHideInDesktop"
				identifier="book-flight"
			>
				<SubTabBookFlight
					ref="subTabBookFlightEl"
					class="pt-6"
					:payingByEnrichLabel="props.labels?.paymentType?.payingByEnrichLabel"
					:hideTodayDateIndicator="props.labels?.paymentType?.hideTodayDateIndicator"
					:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
					:note="props.bookFlight?.general?.note"
					:searchFlightCTAText="props.bookFlight?.general?.searchFlightCTAText"
					:ondSelection="props.bookFlight?.ondSelection"
					:additionalONDFilter="props.bookFlight?.general?.ondFilter"
					:datePicker="props.bookFlight?.datePicker"
					:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
					:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"
					:supersedePromoCode="props.bookFlight?.promoCode?.supersedePromoCode"
					:passengerSelection="props.bookFlight?.passengerSelection"
					:bstNoteTop="props.bonusSideTrip?.bstPage?.bstNoteTop"
					:bstNoteCTAText="props.bonusSideTrip?.bstPage?.bstNoteCTAText"
					:bstNoteCTAURL="props.bonusSideTrip?.bstPage?.bstNoteCTAURL"
					:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
					:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
					:flightSearchApiUrl="props.flightSearchApiUrl"
					:cabinClassCashList="props.cabinClassCashList"
					:cabinClassMilesList="props.cabinClassMilesList"
					:togglePointsApiURL="props.togglePointsApiURL"
					:enableMattaToggleButton="props.labels?.mattaFair?.enableMattaToggleButton"
					:mattaToggleButtonLabel="props.labels?.mattaFair?.mattaToggleButtonLabel"
					:hideEnrichToggle="props.labels?.paymentType?.hideEnrichToggle"
					:hidePromoCodeField="props.bookFlight?.promoCode?.hidePromoCodeField"
					:enrichAlwaysOn="props.bookFlight?.enrichAlwaysOn?.enableFunctionality"
					:enablePostMattaCampaign="props.bookFlight?.enrichAlwaysOn?.enablePostMattaCampaign"
					:nonLoggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.nonLoggedInMessageNote"
					:loggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.loggedInMessageNote"
					:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
					:disableGeolocation="props.labels?.nearYou?.disableGeolocation"
					:enableONDLocaleTranslation="props.labels?.ondLocale?.isEnableLocale"
					:countryVariants="props.countryVariants"
					@save-search-data="handleSaveSearchData({ isBSTTab: false, ...$event })"
					@show-bst-overlay="handleShowBSTOverlay"
					@form-submission="sendFormCompleteAA('book-flight')"
				></SubTabBookFlight>
			</div>
			<div
				v-if="!props.multiCity?.general?.isHideInDesktop"
				identifier="multi-city"
			>
				<SubTabMultiCity
					class="pt-6"
					:subHeaderTabDescription="props.multiCity?.general?.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.multiCity?.general?.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.multiCity?.general?.subHeaderTabTooltipLabel"
					:note="props.multiCity?.general?.note"
					:removeCTAText="props.multiCity?.general?.removeCTAText"
					:addAnotherFlightCTAText="props.multiCity?.general?.addAnotherFlightCTAText"
					:searchFlightCTAText="props.multiCity?.general?.searchFlightCTAText"
					:ondSelection="props.multiCity?.ondSelection"
					:datePicker="props.multiCity?.datePicker"
					:passengerSelection="props.multiCity?.passengerSelection"
					:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
					:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
					:flightSearchApiUrl="props.flightSearchApiUrl"
					:cabinClassCashList="props.cabinClassCashList"
					:enableONDLocaleTranslation="props.labels?.ondLocale?.isEnableLocale"
					:promoCode="props.multiCity?.promoCode"
					:countryVariants="props.countryVariants"
					@form-submission="sendFormCompleteAA('multi-city')"
				></SubTabMultiCity>
			</div>
			<div
				v-if="!props.bonusSideTrip?.general?.isHideInDesktop"
				identifier="bonus-side-trip"
			>
				<SubTabBonusSideTrip
					ref="subTabBonusSideTripEl"
					class="pt-6"
					:subHeaderTabDescription="props.bonusSideTrip?.general?.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.bonusSideTrip?.general?.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.bonusSideTrip?.general?.subHeaderTabTooltipLabel"
					:note="props.bonusSideTrip?.general?.note"
					:searchFlightCTAText="props.bonusSideTrip?.general?.searchFlightCTAText"
					:ondSelection="props.bonusSideTrip?.ondSelection"
					:datePicker="props.bonusSideTrip?.datePicker"
					:passengerSelection="props.bonusSideTrip?.passengerSelection"
					:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
					:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
					:cabinClassCashList="props.cabinClassCashList"
					:enableONDLocaleTranslation="props.labels?.ondLocale?.isEnableLocale"
					:countryVariants="props.countryVariants"
					@save-search-data="handleSaveSearchData({ isBSTTab: true, ...$event })"
					@show-bst-overlay="handleShowBSTOverlay"
					@form-submission="sendFormCompleteAA('bonus-side-trip')"
				></SubTabBonusSideTrip>
			</div>
		</VueTabs>

		<ContinueYourSearch
			v-if="!props.isHideContinueYourSearchSection"
			ref="continueYourSearchComponent"
			class="continue-your-search-container"
			:continueYourSearchLabel="props.bookFlight?.continueYourSearch?.continueYourSearchLabel"
			:economyLabel="props.bookFlight?.passengerSelection?.economyLabel"
			:businessLabel="props.bookFlight?.passengerSelection?.businessLabel"
			:businessSuiteLabel="props.bookFlight?.passengerSelection?.businessSuiteLabel"
			@restore-past-search="restorePastSearch"
		></ContinueYourSearch>
	</div>
</div>


<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>

	<template #banner-title>
		<h1 class="text-center">{{ $t('Flight Search') }}</h1>
	</template>

	<template #default>
		<div class="generic-container mb-20">
			<VueTabs
				ref="vueTabsComponent"
				v-model="activeSubTab"
				renderPanelsWithVShow
				@update:modelValue="$emit('update:active-sub-tab', $event)"
			>
				<template v-if="!props.bookFlight?.general?.isHideInMobile && props.labels?.flightType?.bookFlightLabel" #tab-book-flight>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.labels?.flightType?.bookFlightLabel }}</span>
					</div>
				</template>
				<template v-if="!props.multiCity?.general?.isHideInMobile && props.labels?.flightType?.multiCityLabel" #tab-multi-city>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.labels?.flightType?.multiCityLabel }}</span>
					</div>
				</template>
				<template v-if="!props.labels?.flightType?.isHideInMobile && props.labels?.flightType?.roundTheWorldLabel" #link-round-the-world>
					<AppHyperlink
						v-aa="[
							{
								clickName: props.labels?.flightType?.roundTheWorldLabel,
								clickComponentType: 'URL',
								componentName: 'FlightSearch',
								componentID: props.componentIdAA,
							},
							{
								name: props.labels?.flightType?.roundTheWorldLabel,
								type: isUrlMatchCurrentHostname(props.labels?.flightType?.roundTheWorldURL) ? 'other' : 'exit',
							},
						]"
						:href="props.labels?.flightType?.roundTheWorldURL"
						class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
						:target="props.labels?.flightType?.openInNewTab ? '_blank' : '_self'"
						:autoExternalLinkIcon="true"
					>{{ props.labels?.flightType?.roundTheWorldLabel }}</AppHyperlink>
				</template>
				<template v-if="!props.bonusSideTrip?.general?.isHideInMobile && props.labels?.flightType?.bonusSideTripLabel" #tab-bonus-side-trip>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.labels?.flightType?.bonusSideTripLabel }}</span>
					</div>
				</template>

				<div
					v-if="!props.bookFlight?.general?.isHideInMobile"
					identifier="book-flight"
				>
					<SubTabBookFlight
						ref="subTabBookFlightEl"
						class="pt-6"
						:payingByEnrichLabel="props.labels?.paymentType?.payingByEnrichLabel"
						:hideTodayDateIndicator="props.labels?.paymentType?.hideTodayDateIndicator"
						:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
						:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
						:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
						:note="props.bookFlight?.general?.note"
						:searchFlightCTAText="props.bookFlight?.general?.searchFlightCTAText"
						:ondSelection="props.bookFlight?.ondSelection"
						:additionalONDFilter="props.bookFlight?.general?.ondFilter"
						:datePicker="props.bookFlight?.datePicker"
						:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
						:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"
						:supersedePromoCode="props.bookFlight?.promoCode?.supersedePromoCode"
						:passengerSelection="props.bookFlight?.passengerSelection"
						:bstNoteTop="props.bonusSideTrip?.bstPage?.bstNoteTop"
						:bstNoteCTAText="props.bonusSideTrip?.bstPage?.bstNoteCTAText"
						:bstNoteCTAURL="props.bonusSideTrip?.bstPage?.bstNoteCTAURL"
						:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
						:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
						:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
						:datePickerQuestionMobile="props.mobileAddOns?.datePickerQuestionMobile"
						:passengerSelectionQuestion="props.mobileAddOns?.passengerSelectionQuestion"
						:flightSearchApiUrl="props.flightSearchApiUrl"
						:cabinClassCashList="props.cabinClassCashList"
						:cabinClassMilesList="props.cabinClassMilesList"
						:togglePointsApiURL="props.togglePointsApiURL"
						:enableMattaToggleButton="props.labels?.mattaFair?.enableMattaToggleButton"
						:mattaToggleButtonLabel="props.labels?.mattaFair?.mattaToggleButtonLabel"
						:hideEnrichToggle="props.labels?.paymentType?.hideEnrichToggle"
						:hidePromoCodeField="props.bookFlight?.promoCode?.hidePromoCodeField"
						:enrichAlwaysOn="props.bookFlight?.enrichAlwaysOn?.enableFunctionality"
						:enablePostMattaCampaign="props.bookFlight?.enrichAlwaysOn?.enablePostMattaCampaign"
						:nonLoggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.nonLoggedInMessageNote"
						:loggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.loggedInMessageNote"
						:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
						:disableGeolocation="props.labels?.nearYou?.disableGeolocation"
						:disableCalendarPickerAnimation="true"
						@save-search-data="handleSaveSearchData({ isBSTTab: false, ...$event })"
						@show-bst-overlay="handleShowBSTOverlay"
						@form-submission="sendFormCompleteAA('book-flight')"
					></SubTabBookFlight>
				</div>
				<div
					v-if="!props.multiCity?.general?.isHideInMobile"
					identifier="multi-city"
				>
					<SubTabMultiCity
						class="pt-6"
						:subHeaderTabDescription="props.multiCity?.general?.subHeaderTabDescription"
						:subHeaderTabTooltipBody="props.multiCity?.general?.subHeaderTabTooltipBody"
						:subHeaderTabTooltipLabel="props.multiCity?.general?.subHeaderTabTooltipLabel"
						:note="props.multiCity?.general?.note"
						:removeCTAText="props.multiCity?.general?.removeCTAText"
						:addAnotherFlightCTAText="props.multiCity?.general?.addAnotherFlightCTAText"
						:searchFlightCTAText="props.multiCity?.general?.searchFlightCTAText"
						:ondSelection="props.multiCity?.ondSelection"
						:datePicker="props.multiCity?.datePicker"
						:passengerSelection="props.multiCity?.passengerSelection"
						:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
						:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
						:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
						:datePickerQuestionMobile="props.mobileAddOns?.datePickerQuestionMobile"
						:passengerSelectionQuestion="props.mobileAddOns?.passengerSelectionQuestion"
						:flightSearchApiUrl="props.flightSearchApiUrl"
						:cabinClassCashList="props.cabinClassCashList"
						:promoCode="props.multiCity?.promoCode"
						@form-submission="sendFormCompleteAA('multi-city')"
					></SubTabMultiCity>
				</div>
				<div
					v-if="!props.bonusSideTrip?.general?.isHideInMobile"
					identifier="bonus-side-trip"
				>
					<SubTabBonusSideTrip
						ref="subTabBonusSideTripEl"
						class="pt-6"
						:subHeaderTabDescription="props.bonusSideTrip?.general?.subHeaderTabDescription"
						:subHeaderTabTooltipBody="props.bonusSideTrip?.general?.subHeaderTabTooltipBody"
						:subHeaderTabTooltipLabel="props.bonusSideTrip?.general?.subHeaderTabTooltipLabel"
						:note="props.bonusSideTrip?.general?.note"
						:searchFlightCTAText="props.bonusSideTrip?.general?.searchFlightCTAText"
						:ondSelection="props.bonusSideTrip?.ondSelection"
						:datePicker="props.bonusSideTrip?.datePicker"
						:passengerSelection="props.bonusSideTrip?.passengerSelection"
						:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
						:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
						:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
						:datePickerQuestionMobile="props.mobileAddOns?.datePickerQuestionMobile"
						:passengerSelectionQuestion="props.mobileAddOns?.passengerSelectionQuestion"
						:cabinClassCashList="props.cabinClassCashList"
						@save-search-data="handleSaveSearchData({ isBSTTab: true, ...$event })"
						@show-bst-overlay="handleShowBSTOverlay"
						@form-submission="sendFormCompleteAA('bonus-side-trip')"
					></SubTabBonusSideTrip>
				</div>
			</VueTabs>
		</div>
	</template>

</MobileSubpage>


<OverlayBonusSideTrip
	:isVisible="isBSTOverlayVisible"
	:bstNoteTop="props.bonusSideTrip?.bstPage?.bstNoteTop"
	:bstNoteCTAText="props.bonusSideTrip?.bstPage?.bstNoteCTAText"
	:bstNoteCTAURL="props.bonusSideTrip?.bstPage?.bstNoteCTAURL"
	:bstEditSearchCTAText="props.bonusSideTrip?.bstPage?.bstEditSearchCTAText"
	:optionLabel="props.bonusSideTrip?.bstPage?.optionLabel"
	:visitMalaysiaLabel="props.bonusSideTrip?.bstPage?.visitMalaysiaLabel"
	:yourDateOfChoiceLabel="props.bonusSideTrip?.bstPage?.yourDateOfChoiceLabel"
	:question1Text="props.bonusSideTrip?.bstPage?.question1Text"
	:question2Text="props.bonusSideTrip?.bstPage?.question2Text"
	:continueWithoutBSTCTAText="props.bonusSideTrip?.bstPage?.continueWithoutBSTCTAText"
	:continueCTAText="props.bonusSideTrip?.bstPage?.continueCTAText"
	:toLabel="props.bonusSideTrip?.bstPage?.toLabel"
	:departLabel="props.bonusSideTrip?.bstPage?.departLabel"
	:bstQuestion1MobileLabel="props.mobileAddOns?.bstQuestion1MobileLabel"
	:bstQuestion2MobileLabel="props.mobileAddOns?.bstQuestion2MobileLabel"
	:bstDisabledContinueCtaLabel="props.bonusSideTrip?.bstPage?.disabledContinueCtaLabel"

	:ondSelection="props.bonusSideTrip?.ondSelection"
	:datePicker="props.bonusSideTrip?.datePicker"
	:passengerSelection="props.bonusSideTrip?.passengerSelection"
	:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
	:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
	:datePickerQuestionMobile="props.mobileAddOns?.datePickerQuestionMobile"
	:passengerSelectionQuestion="props.mobileAddOns?.passengerSelectionQuestion"

	:cabinClassCashList="props.cabinClassCashList"
	:cabinClassMilesList="props.cabinClassMilesList"

	:flightSearchApiUrl="props.flightSearchApiUrl"

	:componentIdAA="props.componentIdAA"

	:extraONDFilterForFlightSearch="{
		additionalONDFilter: props.bookFlight?.general?.ondFilter,
		ondSelection: props.bookFlight?.ondSelection,
	}"

	@update:is-visible="isBSTOverlayVisible = $event"
></OverlayBonusSideTrip>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FlightSearch {

}

[data-use-theme="enrich-portal"] {
	:deep(.DateRangePicker) {
		.floating-panel {
			width: 100%;
		}
	}
}
</style>
