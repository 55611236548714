<script>
export default {
	name: 'DesktopLevelTwo',
	inheritAttrs: false,
};
</script>

<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { onClickOutside } from '@vueuse/core';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	closeMenuCTALabel: { type: String, default: '' },
});
const emit = defineEmits([
	'update:isVisible',
]);

const internalVisible = ref(props.isVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});
watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

useDisableScroll(internalVisible);

const hideOverlay = () => {
	internalVisible.value = false;
};


const handleRootBlurredWithin = (event) => {
	internalVisible.value = false;
};


const rootEl = ref(null);

onClickOutside(rootEl, (event) => {
	internalVisible.value = false;
});


</script>

<template>
<Teleport to="body">
	<div class="generic-backdrop"></div>
</Teleport>
<div
	v-if="isVisible"
	ref="rootEl"
	v-focus-within
	class="DesktopLevelTwo bg-white overflow-hidden"
	v-bind="$attrs"
	tabindex="-1"
	@keydown.esc.capture="hideOverlay"
	@blur-within="handleRootBlurredWithin"
	@vue:mounted="handleRootMounted"
>
	<div class="generic-container py-10 relative">
		<AppHyperlink
			v-if="props.closeMenuCTALabel"
			href="#"
			class="absolute right-4"
			@click="hideOverlay"
		>
			<span class="font-semibold pr-2">{{ props.closeMenuCTALabel }}</span>
			<icon-far-xmark class="w-2.5 inline-block fill-current mt-[3px]" />
		</AppHyperlink>
		<slot></slot>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.DesktopLevelTwo {
	position: fixed;
	z-index: 7000;
	left: 0;
	width: 100%;
	// after applying transform to header, this 'top' need to change
	/* top: calc( var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight) + var(--headerHeight) ); */
	top: var(--headerHeight);
	transition: opacity 0.3s ease;
	min-height: 500px;
	@apply shadow-type-a;
}

</style>
