<script>
export default {
	name: 'AeroDaratHomePageLayout',
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import { gsap } from 'gsap';
import { useEventBus } from '@vueuse/core';
import { generateXfPath } from '@/logic/aem/generate-xf-path';

import Carousel from '~/aem-components/Carousel.vue';



const rootEl = ref(null);

const { isEditorMode } = useGlobalAEMState();
const siteName = window.siteName;

</script>


<template>
<div ref="rootEl" class="AeroDaratHomePageLayout flex flex-col min-h-screen" data-layout="AeroDaratHomepage">
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/ads/$country/$lang/site/header/master"
	/>
	
	<div id="content"></div>
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		:xfPath="generateXfPath({ siteName, xfComponentId: 'cookies-notification' })"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/ads/$country/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
