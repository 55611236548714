<script>
export default {
	name: 'ManageBooking',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import SubTabManageBooking from '~/components/BookingWidget/ManageBooking/SubTabManageBooking.vue';
import SubTabUpgrade from '~/components/BookingWidget/ManageBooking/SubTabUpgrade.vue';
import VueTabs from '~/components/VueTabs.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useUrlSearchParams, useEventBus } from '@vueuse/core';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { pushAA_click } from '~/logic/adobe-analytic.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';

const props = defineProps({
	general: { type: Object, default: null },
	manageBooking: { type: Object, default: null },
	upgrade: { type: Object, default: null },
	isInBookingWidget: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },
});

const emit = defineEmits([
	'update:active-sub-tab',
]);

const { isEditorMode } = useGlobalAEMState();


const rootEl = ref(null);
const activeSubTab = ref('manage-booking');

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['manage-booking', 'manage-booking-tab'],
	rootEl,
});


//  AA for sub tab click tracking
watch(activeSubTab, (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'ManageBooking',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
});

const globalBookFlightBus = useEventBus('booking-widget:book-flight');

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'manage-booking-tab',
	subTabName: 'upgrade',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}
	globalBookFlightBus.emit('active-tab-and-fill-up-form', { mainTab: query.maintab ?? '' });
	activeSubTab.value = query.subtab ?? '';
});



</script>



<template>
<div
	ref="rootEl"
	class="ManageBooking booking-widget-subcomponent"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Manage Booking</span>
		</h5>

		<VueTabs
			v-model="activeSubTab"
			:dormantMode="isEditorMode"
			renderPanelsWithVShow
			:hideTabs="props.general?.isHideSubTabs"
			@update:modelValue="$emit('update:active-sub-tab', $event)"
		>

			<template v-if="!props.manageBooking?.isHideInDesktop && props.manageBooking?.label" #tab-manage-booking>
				<div v-if="props.manageBooking?.label" class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.manageBooking?.label }}</span>
				</div>
			</template>

			<template v-if="!props.upgrade?.isHideInDesktop && props.upgrade?.label" #tab-upgrade>
				<div v-if="props.upgrade?.label" class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.upgrade?.label }}</span>
				</div>
			</template>


			<div
				v-if="!props.manageBooking?.isHideInDesktop && props.manageBooking?.label"
				identifier="manage-booking"
				:class="{
					'py-10': isEditorMode,
				}"
			>
				<SubTabManageBooking
					v-if="!props.manageBooking?.isHideInDesktop"
					:class="{ 'pt-6': !props.general?.isHideSubTabs }"
					:apiUrl="props.manageBooking.apiUrl"
					:bookingReference="props.manageBooking.bookingReference"
					:lastName="props.manageBooking.lastName"
					:description="props.manageBooking.description"
					:tooltipLabel="props.manageBooking.tooltipLabel"
					:tooltipBody="props.manageBooking.tooltipBody"
					:buttonLabel="props.manageBooking.buttonLabel"
					:note="props.manageBooking.note"
					:enableMhUpgrade="props.manageBooking.enableMhUpgrade"
					:upgradeImage="props.manageBooking.upgradeImage"
					:upgradeImageAltText="props.manageBooking.upgradeImageAltText"
					:upgradeText="props.manageBooking.upgradeText"
					:ctaYesBtnText="props.manageBooking.ctaYesBtnText"
					:ctaNoBtnText="props.manageBooking.ctaNoBtnText"
					:mhUpgradeApiUrl="props.manageBooking.mhUpgradeApiUrl"
					:isrefXEnabled="props.manageBooking.enableRefx"
				></SubTabManageBooking>
				<div
					v-else-if="isEditorMode"
					class="generic-container py-10"
				>
					<p class="font-bold">{{ props.manageBooking?.label }}</p>
					<p>Hidden in: <span v-if="props.manageBooking?.isHideInDesktop">Desktop</span> <span v-if="props.manageBooking?.isHideInMobile">, Mobile</span></p>
				</div>
			</div>

			<div
				v-if="!props.upgrade?.isHideInDesktop && props.upgrade?.label"
				identifier="upgrade"
				:class="{
					'py-10': isEditorMode,
				}"
			>
				<SubTabUpgrade
					v-if="!props.upgrade?.isHideInDesktop"
					class="pt-6"
					:apiUrl="props.upgrade.apiUrl"
					:apiPointsUrl="props.upgrade.apiPointsUrl"
					:bookingReference="props.upgrade.bookingReference"
					:lastName="props.upgrade.lastName"
					:description="props.upgrade.description"
					:tooltipLabel="props.upgrade.tooltipLabel"
					:tooltipBody="props.upgrade.tooltipBody"
					:buttonLabel="props.upgrade.buttonLabel"
					:note="props.upgrade.note"
					:isToggle="props.upgrade.isToggle"
					:toggleButtonLabel="props.upgrade.toggleButtonLabel"
					:togglePointsApiURL="props.togglePointsApiURL"
				></SubTabUpgrade>
				<div
					v-else-if="isEditorMode"
					class="generic-container py-10"
				>
					<p class="font-bold">{{ props.upgrade?.label }}</p>
					<p>Hidden in: <span v-if="props.upgrade?.isHideInDesktop">Desktop</span> <span v-if="props.upgrade?.isHideInMobile">, Mobile</span></p>
				</div>
			</div>
		</VueTabs>
	</div>

</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event;"
>

	<template #banner-title>
		<h1 class="text-center">{{ $t('Manage Booking') }}</h1>
	</template>

	<template #default>
		<div class="generic-container mb-20">
			<VueTabs
				v-model="activeSubTab"
				:dormantMode="isEditorMode"
				renderPanelsWithVShow
				@update:modelValue="$emit('update:active-sub-tab', $event)"
			>
				<template v-if="!props.manageBooking?.isHideInMobile" #tab-manage-booking>
					<div v-if="props.manageBooking?.label" class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.manageBooking?.label }}</span>
					</div>
				</template>

				<template v-if="!props.upgrade?.isHideInMobile" #tab-upgrade>
					<div v-if="props.upgrade?.label" class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.upgrade?.label }}</span>
					</div>
				</template>

				<div v-if="!props.manageBooking?.isHideInMobile" identifier="manage-booking">
					<SubTabManageBooking
						v-if="!props.manageBooking?.isHideInMobile"
						class="pt-6"
						:apiUrl="props.manageBooking.apiUrl"
						:bookingReference="props.manageBooking.bookingReference"
						:lastName="props.manageBooking.lastName"
						:description="props.manageBooking.description"
						:tooltipLabel="props.manageBooking.tooltipLabel"
						:tooltipBody="props.manageBooking.tooltipBody"
						:buttonLabel="props.manageBooking.buttonLabel"
						:note="props.manageBooking.note"
						:isrefXEnabled="props.manageBooking.enableRefx"
					></SubTabManageBooking>
				</div>

				<div v-if="!props.upgrade?.isHideInMobile" identifier="upgrade">
					<SubTabUpgrade
						v-if="!props.upgrade?.isHideInMobile"
						class="pt-6"
						:apiUrl="props.upgrade.apiUrl"
						:apiPointsUrl="props.upgrade.apiPointsUrl"
						:bookingReference="props.upgrade.bookingReference"
						:lastName="props.upgrade.lastName"
						:description="props.upgrade.description"
						:tooltipLabel="props.upgrade.tooltipLabel"
						:tooltipBody="props.upgrade.tooltipBody"
						:buttonLabel="props.upgrade.buttonLabel"
						:note="props.upgrade.note"
						:isToggle="props.upgrade.isToggle"
						:toggleButtonLabel="props.upgrade.toggleButtonLabel"
						:togglePointsApiURL="props.togglePointsApiURL"
					></SubTabUpgrade>
				</div>
			</VueTabs>
		</div>
	</template>

</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ManageBooking {
	.is-active {
		background-color: var(--primary-blue-extralight);
	}
}

</style>
