<script>
export default {
	name: 'FlysiswaSubTabBookFlight',
};
</script>

<script setup>
import Tooltip from '~/components/Tooltip.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import dayjs from 'dayjs';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import TransitionHeight from '~/components/transition/TransitionHeight.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useEventBus } from '@vueuse/core';
import { i18nGlobal } from '~/logic/i18n.js';
import { useIsUkVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useLowFarePriceList } from '~composables/booking-widget/useLowFarePriceList.js';
import { formatNumberWithAbbreviation } from '~/logic/helpers/string.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { pushAA_searchError } from '~/logic/adobe-analytic.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';
import AppSpinner from '~/components/AppSpinner.vue';
import ONDPicker from '~/components/mhe/BookingWidget/ONDPicker.vue';
import PassengerAndCabinClass from '~/components/mhe/BookingWidget/PassengerAndCabinClass.vue';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry';
import { encryptionHelper } from '~/logic/composables/encryptionHelper';

const props = defineProps({
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },
	disableGeolocation: { type: Boolean, default: false },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },


	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
	passengerSelectionQuestion: { type: String, default: '' },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },
});

const emit = defineEmits([
	'form-submission',
]);

const { isRTL } = useIsRTL();

const isLoadingPincode = ref(false);
const pincode = ref(null);
const showPincode = ref(false);
const formWrapper = ref(null);
const rootEl = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);
const ondPickerEl = ref(null);
const isLoading = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const payByEnrichPoints = ref(false);
const token = ref('');


const { pageProperties, isEditorMode } = useGlobalAEMState();

const isExpanded = ref(true);


const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;


const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const computedCabinClassValue = computed(() => {
	if (!passengerAndCabinClassEl.value) return;
	/*
		The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
		Economy: 1,
		Business: 2,
		Business Suite: 3.
		While form submission, due to different form has different cabin class value, even though their label(Economy, Business, Business Suite) are the same. 
		require to map to the actual cabin class value, which come from the component config.
	*/
	const index = parseInt(passengerAndCabinClassEl.value.cabinClassValue?.value) - 1;
	
	if (payByEnrichPoints.value) {
		return props.cabinClassMilesList[index].value;
	}
	return props.cabinClassCashList[index].value;
});

const lowFareApiUrl = pageProperties?.value?.lowFareApiUrl;
const isOneWay = ref(false);

// const dateRangePriceData = ref(useLowFarePriceList(ondValueFrom, ondValueTo, dateRangeValueFrom, payByEnrichPoints, lowFareApiUrl));
const {
	priceData: dateRangePriceData,
	currency,
	returnTypeDepartureDateAndFare: departureDateAndFare,
} = useLowFarePriceList({
	originRef: ondValueFrom,
	destinationRef: ondValueTo,
	departureDateRef: dateRangeValueFrom,
	paymentTypeRef: payByEnrichPoints,
	endpointUrl: lowFareApiUrl,
	isOneWayRef: isOneWay,
});

const handleWatchIsOneWay = (flag) => {
	isOneWay.value = flag;
};


const {
	isAuthStateReady,
} = useAuthStore();

const SAPPHIRE_COOKIE = 'sapphire';
token.value = getCookie(SAPPHIRE_COOKIE) ?? null;


const handleSubmitValid = async (values) => {
	console.log('✅ handleSubmitValid values = ', values);

	isLoading.value = true;

	const searchData = {
		isBSTTab: false,
		
		payByEnrichPoints: payByEnrichPoints.value,
		optInBonusSideTrip: false,
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: !dateRangeValueTo.value,
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		promoCodeValue: null,
		cabinClassValue: passengerAndCabinClassEl.value.cabinClassValue,
		adultsCount: passengerAndCabinClassEl.value.adults.count.value,
		teensCount: passengerAndCabinClassEl.value.teens.count.value,
		childrenCount: passengerAndCabinClassEl.value.children.count.value,
		infantsCount: passengerAndCabinClassEl.value.infants.count.value,
		userInputPromoCode: null,
	};
	
	emit('form-submission', searchData);

	try {
		
		// 1st api to get response with url and payload for 2nd api
		const apiResponse = await axios({
			method: 'post',
			url: props.flightSearchApiUrl,
			data: {
				'departDate1': dayjs(searchData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
				'returnDate1': !searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('YYYYMMDDHHmm') : '',
				'originCountry': searchData.ondValueFrom.countryName,
				'originAirportCode1': searchData.ondValueFrom.code,
				'destAirportCode1': searchData.ondValueTo.code,
				'flightClass': computedCabinClassValue.value,
				'adultCount': searchData.adultsCount.toString(),
				...(isUKVariant.value ? { 'teenCount': searchData.teensCount.toString() } : null),
				'childCount': searchData.childrenCount.toString(),
				'infantCount': searchData.infantsCount.toString(),
				'paymentType': searchData.payByEnrichPoints ? 'miles' : 'cash',
				'amal1': searchData.ondValueTo._original.isAmalFlag,
				'promoCode': searchData.promoCodeValue ?? '',
				'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
				'amcvId': window._satellite?.getVar('ECID') ?? '',
				'teaserCategory': getCookie('TeaserCategory') ?? '',
				'mhFlysiswa': true,
			},
		});
		
		const { url, payload } = apiResponse.data;
		
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key]] of entriesPayload.entries()) {
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		isLoading.value = false;
		form.submit();

	} catch (err) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', JSON.stringify(err));

		isLoading.value = false;

		// from BE if status code === 400, it means promo code issue
		const { statusCode, message } = err.response.data;
		if (statusCode === 400) {
			// promo code error
			triggerAA_searchError({
				errorMsg: i18nGlobal.t(message),
			});
		}
	}
};
const handleSubmitInvalid = ({ errors }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};





const handleFormFocusWithin = () => {
	if (isSticked.value) return; // do not mess with this when in sticky mode
	isExpanded.value = true;
};
const handleFormBlurWithin = () => {
	//
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	const isOneWay = dateRangePickerEl.value?.isOneWay;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});


const bookingWidgetBus = useEventBus('booking-widget');
const isSticked = ref(false);

bookingWidgetBus.on((event) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleExpandTransitionEnd = () => {
	// no need to call this anymore
	// bookingWidgetBus.emit('refresh-scroll-trigger');
};
/* const handleBonusSideTripBannerChanged = () => {
	bookingWidgetBus.emit('refresh-scroll-trigger');
}; */


const areDatesValid = (from, to = null) => {
	const today = dayjs();
	const dayJsMaxAllowedDaysAhead = today.add(datePickerMaxAllowedFutureDays.value, 'day');
	const dayJsFrom = dayjs(from);
	const dayJsTo = dayjs(to);
	
	if (dayJsFrom.isValid() === false || (to && dayJsTo.isValid() === false)) return false;
	
	if (dayJsFrom.isBefore(today, 'day')) {
		return false;
	}
	if (to && dayJsTo.isAfter(dayJsMaxAllowedDaysAhead, 'day')) {
		return false;
	}
	return true;
};

const validateAndRestoreDates = ({ from, to = null, isOneWay = false } = {}) => {
	if (areDatesValid(from, (isOneWay ? null : to))) {
		// valid date
		dateRangeValueFrom.value = dayjs(from).format('YYYY-MM-DD');
		dateRangeValueTo.value = (to && dayjs(to).format('YYYY-MM-DD')) ?? null;
	} else {
		// invalid date
		dateRangeValueFrom.value = null;
		dateRangeValueTo.value = null;
		
		// have to reset the validation here, so that the 'setTouched()' and 'setErrors()' inside the nextTick() will work
		dateRangePickerEl.value?.inputElFrom?.resetField();
		
		nextTick().then(() => {
			try {
				dateRangePickerEl.value?.inputElFrom?.setTouched(true);
				dateRangePickerEl.value?.inputElFrom?.setErrors(i18nGlobal.t('The selected dates are no longer valid. Please reselect.'));
			} catch {
				// do nothing
			}
		});
	}
};


const defaultMinDate = 0;
const defaultMaxDate = 361;
const datePickerMinAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const datePickerMaxAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});

const formattedDisabledDateList = computed(() => {
	let disabledDates = null;
	const disableSpecificDateList = props.datePicker?.disableSpecificDateList;

	if (disableSpecificDateList.length) {
		disabledDates = [];

		disableSpecificDateList.forEach((date) => {
			if (!date.disableDate) return;
			disabledDates.push(date.disableDate);
		});
	}
	return disabledDates;
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'book-flight',
});

registerQueryChange(async (query) => {
	if (!query) return;
	
	// ondValueFrom.value = query.locationFrom ? await getONDValueByAirportCode(query.locationFrom, { direction: 'from' }) : null;
	// ondValueTo.value = query.locationTo ? await getONDValueByAirportCode(query.locationTo, { direction: 'to' }) : null;
	
	ondValueFrom.value = query.locationFrom;
	ondValueTo.value = query.locationTo;
	
	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
	
	validateAndRestoreDates({
		from: query.dateDeparture,
		to: query.dateReturn || null,
		isOneWay: (query.isOneWay === 'true'),
	});
	
	
	await nextTick();

	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.teensCount ? { teens: parseInt(query.teensCount) } : null),
		...(query.childrenCount ? { children: parseInt(query.childrenCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
		...(query.cabinClass ? { cabinClass: parseInt(query.cabinClass) } : null),
	});


	if (query.isPoints === 'true') {
		setEnrichPointStatus(true);
	}

	if (query.isOneWay === 'true') {
		dateRangePickerEl.value.handleSetIsOneWay(!!query.isOneWay);
	}
});

const updateLocationFromTo = async (payload) => {
	const { locationFrom, locationTo } = payload;
	// if (locationFrom) ondValueFrom.value = await getONDValueByAirportCode(locationFrom, { direction: 'from' });
	// if (locationTo) ondValueTo.value = await getONDValueByAirportCode(locationTo, { direction: 'to' });

	if (locationFrom) ondValueFrom.value = locationFrom;
	if (locationTo) ondValueTo.value = locationTo;

	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
};


const sumPrice = ref(0);

watch(dateRangeValueTo, (newValue) => {
	if (
		newValue &&
		ondValueFrom.value &&
		ondValueTo.value &&
		dateRangeValueTo.value &&
		dateRangePriceData.value &&
		dateRangePriceData.value[dateRangeValueTo.value] &&
		departureDateAndFare?.value?.totalFareAmount
	) {
		sumPrice.value = parseInt(departureDateAndFare.value.totalFareAmount) + parseInt(dateRangePriceData.value[dateRangeValueTo.value]);
		return;
	}

	sumPrice.value = 0;
});

const sumPriceWithCurrency = computed(() => {
	if (!currency.value && !sumPrice.value) return '';
	return `${currency.value} ${formatNumberWithAbbreviation(sumPrice.value)}`;
});

const isUKVariant = ref(false);


watch([ondValueFrom, ondValueTo], async () => {
	// reset teen passenger count, once user select non-uk airport
	isUKVariant.value = useIsUkVariant({ origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isUKVariant.value) return;
	passengerAndCabinClassEl.value?.setValues({
		teens: 0,
	});
});

async function setEnrichPointStatus (flag) {
	if (!flag || !token.value) {
		payByEnrichPoints.value = false;
		return;
	}
	if (!isAuthStateReady.value) {
		const waitForAuthReady = waitFor(() => !!isAuthStateReady.value);
		await waitForAuthReady.start();
	}
	payByEnrichPoints.value = true;
}

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.searchFlightCTAText,
		type: 'exit',
	};
	pushAA_searchError([errorInfo, webInteractions]);
};

useSyncPassengerDetails(passengerAndCabinClassEl);

const globalBookFlightBus = useEventBus('booking-widget:enrich-points-toggle-switch');
globalBookFlightBus.on(async (event, status) => {
	switch (event) {
		case 'update-toggle-switch': {
			if (status === 'logout') {
				setEnrichPointStatus(false);
			}
			await nextTick();
			break;
		}
			
		default: {
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
		}
	}
});

defineExpose({
	updateLocationFromTo,
	isExpanded,
});

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) dateRangePickerEl.value?.inputElFrom?.focus();
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withMalaysiaAirlinesLabel,
	};
});

const { retrieveLocalStorage } = usePersistencyWithExpiry('userInfo');
const { decrypt } = encryptionHelper();
const loginUserDetail = ref(null);

const handleRevealPincode = async () => {
	// no need to call api again if value alrady there (just show & hide)
	if (pincode.value) {
		showPincode.value = !showPincode.value;
		return;
	}
	isLoadingPincode.value = true;
	try {
		const formData = new FormData();
		formData.append('voucherCode', loginUserDetail.value?.flysiswaVoucherCode);
		formData.append('enrichId', loginUserDetail.value?.enrichId);
		const { data } = await axios.post('/bin/mh/revamp/flysiswa/retrieveVoucherPin', formData);
		if (data && data.status) {
			pincode.value = data.voucherPin;
			showPincode.value = true;
		}
	} catch (e) {
		pincode.value = null;
		showPincode.value = false;
		console.error('handleRevealPincode, ', 'Unable to fetch api', e);
	} finally {
		isLoadingPincode.value = false;
	}
};

onMounted(async () => {
	const storedData = await retrieveLocalStorage();
	if (storedData?.userInfo) {
		try {
			const decryptedData = await decrypt(storedData.userInfo);
			loginUserDetail.value = JSON.parse(decryptedData);
		} catch (error) {
			console.error('Failed to decrypt user data:', error);
		}
	}
});
</script>

<template>
<div ref="rootEl" class="FlysiswaSubTabBookFlight">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		v-focus-within
		class="lg:mt-0"
		tabindex="-1"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
		@focus-within="handleFormFocusWithin"
		@blur-within="handleFormBlurWithin"
	>
		<div>

			<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

			<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
				<template #default>
					<div class="flex items-center">
						<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
						{{ props.subHeaderTabTooltipLabel }}
					</div>
				</template>
				<template #mobile-title>
					{{ props.subHeaderTabTooltipLabel }}
				</template>
				<template #tooltip-content>
					<div v-html-sanitize="props.subHeaderTabTooltipBody"></div>
				</template>
				<template #mobile-cta-label>
					{{ props.subHeaderTooltipCTATextMobileLabel }}
				</template>
			</Tooltip>
			<div class="flex gap-6 xl:gap-3 lg:flex-col mb-5 justify-end items-center">
				<div class="text-base font-medium">
					<span>
						{{ $t('Flysiswa Voucher Code') }}:
						<span>{{ loginUserDetail?.flysiswaVoucherCode }}</span>
					</span>
				</div>
				<div class="text-base font-medium">
					<span>
						{{ $t('Pincode') }}:
						<span
							v-if="showPincode && pincode"
						>
							{{ pincode }}
						</span>
					</span>
					<button
						type="button"
						class="text-primary-blue-base hover:text-primary-blue-light text-base font-medium"
						@click="handleRevealPincode"
					>
						<div class="flex items-center justify-center">
							<AppSpinner
								v-if="isLoadingPincode"
								:size="15"
							/>
							<div v-if="!showPincode && !isLoadingPincode">{{ $t('click to reveal') }}</div>
							<div v-if="showPincode && !isLoadingPincode">{{ $t('hide') }}</div>
						</div>
					</button>
				</div>
			</div>
			<div class="flex gap-6 xl:gap-3 lg:flex-col">
				<div class="w-6/10 lg:w-full">
					<ONDPicker
						ref="ondPickerEl"
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						ondListIdentifier="FLIGHT_SEARCH_FLYSISWA"
						:requiredFrom="true"
						:requiredTo="true"
						:specificOriginList="props.ondSelection?.showSpecificOrigin"
						:specificDestinationList="props.ondSelection?.showSpecificDestination"
						:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
						:labelTextFrom="props.ondSelection?.fromLabel"
						:labelTextTo="props.ondSelection?.toLabel"
						:noResultsText="props.ondSelection?.noResultMessage"
						:requestGeolocation="!props.disableGeolocation"
						:groupLabelsOverwrite="groupLabelsOverwrite"
						:enableONDLocaleTranslation="props.enableONDLocaleTranslation"
						@update:modelValueTo="focusToDatePicker"
					>
						<template #from-picker-mobile-title>
							<span>{{ props.fromQuestionMobile }}</span>
						</template>
						<template #to-picker-mobile-title>
							<span>{{ props.toQuestionMobile }}</span>
						</template>
					</ONDPicker>
				</div>
				<div class="w-4/10 lg:w-full">
					<DateRangePicker
						ref="dateRangePickerEl"
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:priceData="dateRangePriceData"
						:textFieldAttrsFrom="{
							ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
							placeholder: $t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.datePicker?.returnDateLabel ?? $t('Return'),
							placeholder: $t('Select a date'),
						}"
						:minDate="`t+${datePickerMinAllowedFutureDays}d`"
						:maxDate="`t+${datePickerMaxAllowedFutureDays}d`"
						:disabledDates="formattedDisabledDateList"
						minDateFromAfterSelectingTo="t"
						:disablePastDates="true"
						nameFrom="dateDeparture"
						nameTo="dateReturn"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
						:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
						:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
						:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
						:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
						:isHideTodayIndicator="props.hideTodayDateIndicator"
						:disableCalendarPickerAnimation="props.disableCalendarPickerAnimation"
						@update:is-one-way="handleWatchIsOneWay"
					>
						<template #mobile-title>
							{{ props.datePickerQuestionMobile }}
						</template>
						
						<template v-if="currency" #before-cta-done>
							<span v-if="ondValueFrom && !sumPrice" v-html-sanitize="$t('Fare based on <strong>{currency}</strong>', { currency })"></span>

							<span v-if="ondValueFrom && ondValueTo && sumPrice" v-html-sanitize="$t('Trip from <strong>{sumPriceWithCurrency}</strong> per adult', { sumPriceWithCurrency })"></span>
						</template>
					</DateRangePicker>
				</div>
			</div>
		</div>
		<TransitionHeight
			:height="((isExpanded || isEditorMode || isMobileViewport) ? 'auto' : 0)"
			:class="((isExpanded || isEditorMode || isMobileViewport) ? 'overflow-visible' : '')"
			:transitionDuration="0.175"
			@transition-end="handleExpandTransitionEnd"
		>
			<div class="flex mt-6 gap-6 xl:gap-3 lg:flex-col">
				<div class="flex flex-grow gap-6 xl:gap-3 lg:flex-col">
					<div class="w-6/10 lg:w-full">
						<PassengerAndCabinClass
							ref="passengerAndCabinClassEl"
							:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
							:passengerLabel="props.passengerSelection?.passengerLabel"
							:adultLabel="props.passengerSelection?.adultLabel"
							:teenagerLabel="props.passengerSelection?.teenagerLabel"
							:childrenLabel="props.passengerSelection?.childrenLabel"
							:infantLabel="props.passengerSelection?.infantLabel"
							:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
							:economyLabel="props.passengerSelection?.economyLabel"
							:businessLabel="props.passengerSelection?.businessLabel"
							:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
							:isShowEconomyClass="!props.passengerSelection?.hideEconomy"
							:isShowBusinessClass="!props.passengerSelection?.hideBusiness"
							:isShowBusinessSuiteClass="!props.passengerSelection?.isHideBusinessSuite"
							:isUKVariant="useIsUkVariant({ origin: ondValueFrom, destination: ondValueTo })"
							:isShowInfant="props.passengerSelection?.isShowInfant"
							:isShowChildren="props.passengerSelection?.isShowChildren"
						>
							<template #mobile-title>
								{{ props.passengerSelectionQuestion }}
							</template>
						</PassengerAndCabinClass>
					</div>
				</div>
				<div class="ml-auto lg:ml-0">
					<CTAButton
						v-aa="[
							{
								clickName: props.searchFlightCTAText,
								clickComponentType: 'Button',
								componentName: 'FlysiswaSubTabBookFlight',
								componentID: props.componentIdAA,
							},
							{
								name: props.searchFlightCTAText,
								type: 'exit',
							},
						]"
						:sizeType="isMobileViewport ? 'small' : 'large'"
						:isSubmit="true"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						:disabled="isBtnSubmitDisabled"
					>
						{{ props.searchFlightCTAText }}
					</CTAButton>
				</div>
			</div>

			<div
				v-if="props.note"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.note"
					class="text-sm"
				>
				</div>
			</div>
		</TransitionHeight>
		
	</FormWrapper>

	<Teleport v-if="isLoading" to="body">
		<div class="generic-backdrop !bg-white/80">
			<div class="flex items-center justify-center h-full w-full">
				<AppSpinner />
			</div>
		</div>
	</Teleport>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FlysiswaSubTabBookFlight {
	
}

</style>
