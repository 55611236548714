<script>
export default {
	name: 'DefaultLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import HiddenSeamlessBookingWidget from '~/components/HiddenSeamlessBookingWidget.vue';
</script>


<template>
<div class="DefaultLayout flex flex-col min-h-screen" data-layout="Default">

	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/ads/$country/$lang/site/header/master"
	/>
	<div id="content"></div>
	<div id="booking-subpage-container"></div>
	<main>
		<slot></slot>
	</main>

	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/ads/$country/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';
:slotted(.page) {
	min-height: 400px;
}
</style>