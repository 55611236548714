<script>
export default {
	name: 'FlightStatus',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import VueTabs from '~/components/VueTabs.vue';
import SubTabCities from '~/components/BookingWidget/FlightStatus/SubTabCities.vue';
import SubTabFlightNumber from '~/components/BookingWidget/FlightStatus/SubTabFlightNumber.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { pushAA_flightStatusStart, pushAA_flightStatusEnd, pushAA_click } from '~/logic/adobe-analytic.js';

const props = defineProps({
	isInBookingWidget: { type: Boolean, default: false },
	cities: { type: Object, default: () => null },
	flightNumber: { type: Object, default: () => null },
	flightStatusEndpointUrl: { type: String, default: '' },
	fireflyFlightStatusEndpointUrl: { type: String, default: '' },
	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'update:active-sub-tab',
]);

const rootEl = ref(null);
const activeSubTab = ref('cities');

const computedFlightStatusEndpointUrl = computed(() => {
	return (window.siteName === 'firefly') ? props.fireflyFlightStatusEndpointUrl : props.flightStatusEndpointUrl;
});

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['flight-status', 'flight-status-tab'],
	rootEl,
});

const { isEditorMode } = useGlobalAEMState();

//  AA for sub tab click tracking
watch(activeSubTab, (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'FlightStatus',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
});

let AAHasSentStart = false;
let AAHasSentEnd = false;

onMounted(() => {
	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;

		AAHasSentStart = true;
		pushAA_flightStatusStart(activeSubTab.value);
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};
	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});

const sendFormCompleteAA = (subtabName, flightData) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;

	switch (subtabName) {
		case 'cities': {
			pushAA_flightStatusEnd({
				origin: flightData['flight-status-cities-from'],
				destination: flightData['flight-status-cities-to'],
				date: flightData['flight-status-cities-date'],
			}, subtabName);
			break;
		}
		case 'flight-number': {
			pushAA_flightStatusEnd({
				flightNumber: flightData['flight-status-flight-number'],
				date: flightData['flight-status-flight-number-date'],
			}, subtabName);
			break;
		}
	}
};

const siteName = window.siteName;

</script>



<template>
<div
	ref="rootEl"
	class="FlightStatus booking-widget-subcomponent"
	:data-use-theme="siteName"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Flight Status</span>
		</h5>

		<VueTabs
			v-model="activeSubTab"
			:dormantMode="isEditorMode"
			renderPanelsWithVShow
			@update:modelValue="$emit('update:active-sub-tab', $event)"
		>
			<template v-if="!props.cities?.isHideInDesktop" #tab-cities>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.cities?.label }}</span>
				</div>
			</template>
			<template v-if="!props.flightNumber?.isHideInDesktop" #tab-flight-number>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.flightNumber?.label }}</span>
				</div>
			</template>

			<div identifier="cities">
				<SubTabCities
					v-if="!props.cities?.isHideInDesktop && props.cities?.label"
					class="pt-6"
					:buttonLabel="props.cities.buttonLabel"
					:dateField="props.cities.dateField"
					:description="props.cities.description"
					:from="props.cities.from"
					:label="props.cities.label"
					:note="props.cities.note"
					:to="props.cities.to"
					:tooltipBody="props.cities.tooltipBody"
					:tooltipLabel="props.cities.tooltipLabel"
					:externalUrl="computedFlightStatusEndpointUrl"
					@form-submission="sendFormCompleteAA('cities', $event)"
				></SubTabCities>
			</div>
			<div identifier="flight-number">
				<SubTabFlightNumber
					v-if="!props.flightNumber?.isHideInDesktop && props.flightNumber?.label"
					class="pt-6"
					:buttonLabel="props.flightNumber.buttonLabel"
					:dateField="props.flightNumber.dateField"
					:description="props.flightNumber.description"
					:flight="props.flightNumber.flight"
					:label="props.flightNumber.label"
					:note="props.flightNumber.note"
					:tooltipBody="props.flightNumber.tooltipBody"
					:tooltipLabel="props.flightNumber.tooltipLabel"
					:externalUrl="computedFlightStatusEndpointUrl"
					@form-submission="sendFormCompleteAA('flight-number', $event)"
				></SubTabFlightNumber>
			</div>
		</VueTabs>
	</div>
</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event;"
>

	<template #banner-title>
		<h1 class="text-center">{{ $t('Flight Status') }}</h1>
	</template>

	<template #default>
		<div class="generic-container mb-20">
			<VueTabs
				v-model="activeSubTab"
				:dormantMode="isEditorMode"
				renderPanelsWithVShow
				@update:modelValue="$emit('update:active-sub-tab', $event)"
			>
				<template v-if="!props.cities?.isHideInMobile" #tab-cities>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.cities?.label }}</span>
					</div>
				</template>
				<template v-if="!props.flightNumber?.isHideInMobile" #tab-flight-number>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.flightNumber?.label }}</span>
					</div>
				</template>

				<div identifier="cities">
					<SubTabCities
						v-if="!props.cities?.isHideInMobile && props.cities?.label"
						class="pt-6"
						:buttonLabel="props.cities.buttonLabel"
						:dateField="props.cities.dateField"
						:description="props.cities.description"
						:from="props.cities.from"
						:label="props.cities.label"
						:note="props.cities.note"
						:to="props.cities.to"
						:tooltipBody="props.cities.tooltipBody"
						:tooltipLabel="props.cities.tooltipLabel"
						:externalUrl="computedFlightStatusEndpointUrl"
						@form-submission="sendFormCompleteAA('cities')"
					></SubTabCities>
				</div>
				<div identifier="flight-number">
					<SubTabFlightNumber
						v-if="!props.flightNumber?.isHideInMobile && props.flightNumber?.label"
						class="pt-6"
						:buttonLabel="props.flightNumber.buttonLabel"
						:dateField="props.flightNumber.dateField"
						:description="props.flightNumber.description"
						:flight="props.flightNumber.flight"
						:label="props.flightNumber.label"
						:note="props.flightNumber.note"
						:tooltipBody="props.flightNumber.tooltipBody"
						:tooltipLabel="props.flightNumber.tooltipLabel"
						:externalUrl="computedFlightStatusEndpointUrl"
						@form-submission="sendFormCompleteAA('flight-number')"
					></SubTabFlightNumber>
				</div>
			</VueTabs>
		</div>
	</template>

</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FlightStatus {

}

.FlightStatus[data-use-theme="firefly"] {

	:deep(.VueTabs .tab-button) {

		&:not(:disabled):hover {
			background-color: var(--neutral-firefly-grey-light);
		}

		&.is-active {
			background-color: var(--secondary-firefly-orange-medium);
			color: white;

			&:hover {
				background-color: var(--secondary-firefly-orange-medium);
			}
		}
	}
}
</style>
