import { createGlobalState } from '@vueuse/core';
import { getCookie, deleteCookie } from '~/logic/helpers/cookies.js';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry';

const SAPPHIRE_COOKIE = 'sapphire';
const PHANTOM_COOKIE = 'phantom';
const USERNAME_COOKIE = 'userName';
const MILESCOUNT_COOKIE = 'milesCount';
const FLYERID_COOKIE = 'flyerID';
const MEMBERSTATUS_COOKIE = 'memberStatus';
const JADE_COOKIE = 'jade';
const { writeLocalStorage } = usePersistencyWithExpiry('userInfo', {});
// disable this because we are not using the shadow cookies anymore
// const SHADOW_M_COOKIE = 'shadow_m';
// const SHADOW_N_COOKIE = 'shadow_n';
// const SHADOW_T_COOKIE = 'shadow_t';

export const useAuthStore = createGlobalState(
	() => {
		const userInfo = ref(null);
		const enrichSummaryInfo = ref(null);
		const enrichMembershipInfo = ref(null);
		
		const _getIsLoggedInStatus = () => {
			return getCookie(SAPPHIRE_COOKIE) && userInfo.value;
		};
		const isLoggedIn = ref(_getIsLoggedInStatus());
		const isAuthStateReady = ref(false);
		const isSummaryPageLoaded = ref(false);
		
		const isSapphireCookiePresent = () => {
			return getCookie(SAPPHIRE_COOKIE);
		};
		
		const login = async (_userInfo) => {
			// something...
			userInfo.value = _userInfo;
			refreshAuthStatus();
		};
		const logout = () => {
			deleteCookie(SAPPHIRE_COOKIE);
			deleteCookie(PHANTOM_COOKIE);
			deleteCookie(USERNAME_COOKIE);
			deleteCookie(MILESCOUNT_COOKIE);
			deleteCookie(FLYERID_COOKIE);
			deleteCookie(MEMBERSTATUS_COOKIE);
			deleteCookie(JADE_COOKIE);
			// deleteCookie(SHADOW_M_COOKIE);
			// deleteCookie(SHADOW_N_COOKIE);
			// deleteCookie(SHADOW_T_COOKIE);
			userInfo.value = null;
			enrichSummaryInfo.value = null;
			enrichMembershipInfo.value = null;
			refreshAuthStatus();
		};
		const refreshAuthStatus = () => {
			isLoggedIn.value = _getIsLoggedInStatus();
		};

		const updateEnrichSummary = (_summary) => {
			enrichSummaryInfo.value = _summary;
		};

		const updateEnrichMembership = (_membership) => {
			enrichMembershipInfo.value = _membership;
		};
		
		return {
			// data
			isLoggedIn,
			userInfo,
			isAuthStateReady,
			isSummaryPageLoaded,
			enrichSummaryInfo,
			enrichMembershipInfo,
			
			// method
			login,
			logout,
			refreshAuthStatus,
			isSapphireCookiePresent,
			updateEnrichSummary,
			updateEnrichMembership,
		};
	},
);
